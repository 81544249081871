import { ImageRoutes } from 'core/constants/image-routes';
import { ICatalogueProducts } from 'core/model/interfaces/catalogues.interface';
import {
  IHighlightedProducts,
  IHighlightedProductsPreview,
} from 'core/model/interfaces/highlighted-products.interface';
import { IPaginatedProducts, IProduct, IProductDetail } from 'core/model/interfaces/product.interface';
import {
  IGetHighlightedProductsDto,
  IGetListProductsDTO,
  IIGetHighlightedProductsPreviewDTO,
  INavigationCatalogDTO,
  IProductDetailDTO,
  IProductDTO,
} from './catalogs.dto';
import { getActiveUnitMeasuresDTO, getUnitMeasureDetailFromDTO, getUnitMeasureFromDTO } from './catalogs.utils';

export const productDTOToIProduct = (product: IProductDTO): IProduct => {
  const {
    brand,
    name,
    currency,
    description,
    image,
    sku,
    sourceId,
    stock,
    taxRate,
    unitMeasure,
    eanCode,
    externalCategory,
    externalFamily,
    externalProvider,
    commercialName,
    externalDistributor,
  } = product;

  const activeUnitMeasures = getActiveUnitMeasuresDTO(unitMeasure, product.sku);

  return {
    brand,
    currency,
    name,
    eanCode,
    sku,
    stock,
    sourceId,
    taxRate,
    description,
    unitMeasures: activeUnitMeasures.map(unitMeasure => getUnitMeasureFromDTO(unitMeasure)),
    categoryName: externalCategory.name,
    subcategoryName: externalFamily.name,
    providerDisplayName: externalProvider.name,
    distributorName: externalDistributor.name,
    commercialName: commercialName || name,
    categoryId: Number(externalCategory.id),
    subcategoryId: Number(externalFamily.id),
    image: image || ImageRoutes.CATALOGUE_DEFAULT_PRODUCT,
  };
};

export const navigationCatalogToCatalogueProducts = (
  catalog: INavigationCatalogDTO,
  treeItemType: 'subcategory' | 'category',
): ICatalogueProducts => ({
  [treeItemType]: {
    guid: catalog.treeItemId.toString(),
    name: catalog.description,
  },
  products: mapProductDTOsToIProducts(catalog.catalog.products),
  pagination: catalog.catalog.pagination,
});

export const listProductsToPaginatedProducts = ({ pagination, products }: IGetListProductsDTO): IPaginatedProducts => ({
  products: mapProductDTOsToIProducts(products),
  pagination,
});

export const catalogDetailResponseToProduct = (product: IProductDetailDTO): IProductDetail => {
  const {
    brand,
    name,
    currency,
    description,
    image,
    sku,
    sourceId,
    stock,
    taxRate,
    unitMeasure,
    eanCode,
    externalCategory,
    externalFamily,
    externalProvider,
    commercialName,
    externalDistributor,
  } = product;

  const activeUnitMeasures = getActiveUnitMeasuresDTO(unitMeasure, product.sku);

  const productDetail: IProductDetail = {
    brand,
    currency,
    name,
    eanCode,
    sku,
    stock,
    sourceId,
    taxRate,
    description,
    categoryName: externalCategory.name,
    subcategoryName: externalFamily.name,
    providerDisplayName: externalProvider.name,
    distributorName: externalDistributor.name,
    commercialName: commercialName || name,
    categoryId: Number(externalCategory.id),
    subcategoryId: Number(externalFamily.id),
    image: image || ImageRoutes.CATALOGUE_DEFAULT_PRODUCT,
    unitMeasures: activeUnitMeasures.map(getUnitMeasureDetailFromDTO),
    distributor: {
      id: product.externalDistributor.id,
      name: product.externalDistributor.name,
      document: product.externalDistributor.document,
    },
  };
  return productDetail;
};

export const mapProductDTOsToIProducts = (products: Array<IProductDTO>): Array<IProduct> => {
  const mappedProducts = [];
  for (let i = 0; i < products.length; i += 1) {
    try {
      const product = productDTOToIProduct(products[i]);
      mappedProducts.push(product);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e);
    }
  }
  return mappedProducts;
};

export const highlightedProductsDtoToProducts = ({
  products,
  title,
  pagination,
}: IGetHighlightedProductsDto): IHighlightedProducts => ({
  title,
  pagination,
  products: mapProductDTOsToIProducts(products),
});

export const highlightedProductsDtoToProductsPreview = ({
  products,
  title,
  hasMoreProducts,
  totalProducts,
}: IIGetHighlightedProductsPreviewDTO): IHighlightedProductsPreview => ({
  title,
  products: mapProductDTOsToIProducts(products),
  hasMoreProducts,
  totalProducts,
});
